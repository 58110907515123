import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { WebAssetsContext } from "../../../App";
import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  updateSession,
  loaderBar,
  apibaseURl,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getvalidationErr,
} from "../../../functions/Function";

const StepOne = ({
  inputs,
  setInputs,
  validation,
  setvalidation,
  handleChange,
  validateInputs,
  validtionError,
  step,
  stepvalidation,
  nextStep,
  validationCheckStepOne,
  updateInputState,
  setReorder,
}) => {
  const WebAssets = useContext(WebAssetsContext);
  const api = WebAssets.apiURL;
  const [origins, setOrigins] = useState([]);
  const checkbox = useRef();

  const updateasready = (e) => {
    setInputs((values) => ({ ...values, delivery_type: "asready" }));
    setInputs((values) => ({ ...values, delivery_date: "" }));
  };
  const updateasap = (e) => {
    setInputs((values) => ({ ...values, delivery_date: "" }));
    setInputs((values) => ({ ...values, delivery_type: "asap" }));
  };

  const updateDate = (e) => {
    setInputs((values) => ({ ...values, delivery_date: e.target.value }));
    setInputs((values) => ({ ...values, delivery_type: "" }));
  };

  const updateReorderInput = async (e) => {
    if (inputs.reorder == "No") {
      setInputs((values) => ({ ...values, reorder: "Yes" }));
    } else {
      setInputs((values) => ({ ...values, reorder: "No" }));
    }
  };

  // const updateReorderInput = async (e) => {
  //   if (inputs.reorder == "No") {
  //     if (inputs.buyer_email == "") {
  //       errorToast(
  //         "If you want to order, then first fill the buyer email field, if your order has been made from that email, then all the fields given below will be filled automatically.",
  //         "top-right",
  //         "10000"
  //       );
  //       return false;
  //     }

  //     let res = await fetch(api + "checkPrevious", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         email: inputs.buyer_email,
  //       }),
  //     });
  //     let output = await res.json();
  //     if (res.status === 200) {
  //       if (output.status === true) {
  //         // alert("Test");
  //         // console.log(output.response);
  //         setInputs({ ...output.response, reorder: "Yes" });
  //         // updateInputState(output.response);
  //         // setReorder(true);
  //         //setInputs((values) => ({ ...values, reorder: "Yes" }));
  //       } else {
  //         errorToast(output.response, "top-right", "10000");
  //       }
  //     }
  //   } else {
  //     setInputs((values) => ({ ...values, reorder: "No" }));
  //   }
  // };

  const handleClick = () => {
    if (checkbox.current.checked) {
      setInputs((values) => ({
        ...values,
        fedex_shipping_address: inputs.usps_shipping_address,
      }));
      validateInputs("fedex_shipping_address", inputs.usps_shipping_address);
    } else {
      setInputs((values) => ({ ...values, fedex_shipping_address: "" }));
    }
  };

  const updatefedex_shipping_address = (address) => {
    setInputs((values) => ({ ...values, fedex_shipping_address: address }));
  };

  const handlecheckvalidations = () => {
    validtionError();
    validationCheckStepOne();
  };

  const Originsdropdown = ({ rows }) => {
    return (
      <div className="form-input-box ">
        <select
          name="order_origin"
          className="form-control"
          defaultValue={inputs.order_origin || ""}
          onChange={handleChange}
        >
          <option value={""}>Select Order Origin</option>
          {rows.length > 0 &&
            rows.map((row, i) => (
              <option value={row.title} key={row.id}>
                {row.title}
              </option>
            ))}
        </select>
        {/* <input
          type="text"
          name="order_origin"
          value={inputs.order_origin || ""}
          onChange={handleChange}
          placeholder="Order Origin"
          className="form-control"
        /> */}
        <div className="form-icon">
          <i className="fa-solid fa-building"></i>
        </div>
      </div>
    );
  };

  const fetchOrigins = () => {
    fetch(apibaseURl + "origins")
      .then((res) => res.json())
      .then((result) => {
        if (result.status == true) {
          setOrigins(result.response);
        }
      });
  };
  useEffect(() => {
    if (checkbox.current.checked) {
      updatefedex_shipping_address(inputs.usps_shipping_address);
    }
  }, [inputs.usps_shipping_address]);

  useEffect(() => {
    fetchOrigins();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <ToastContainer />
      <section className="section registratio-box ">
        <div className="container">
          <div className="form-box box-shadow">
            <div className="mb-3 form-input-box">
              <label className="form-label">Order Origin</label>
              <span className="form-star">
                <i className="fa-solid fa-star"></i>
              </span>
              <Originsdropdown rows={origins} />
              {validation.order_origin && inputs.reorder == "No" && (
                <div className="form-text text-danger">
                  {validation.order_origin}
                </div>
              )}
            </div>
            <div className="mb-3 form-input-box">
              {/* <label className="form-label">Reorder</label> */}
              <div className="form-check check-box align-items-center d-flex">
                <input
                  className="form-check-input"
                  type="radio"
                  name="reorder"
                  value="Yes"
                  checked={inputs.reorder === "Yes"}
                  onClick={updateReorderInput}
                  id="flexRadioDefault3"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                  <small>Current Client</small>
                </label>
              </div>
            </div>

            <div className="mb-3 form-input-box">
              <label className="form-label">Buyer Name</label>
              <span className="form-star">
                <i className="fa-solid fa-star"></i>
              </span>
              <div className="form-input-box">
                <input
                  type="text"
                  name="buyer_name"
                  value={inputs.buyer_name || ""}
                  onChange={handleChange}
                  placeholder="Buyer Name"
                  className="form-control"
                />
                <div className="form-icon">
                  <i className="fa-solid fa-building"></i>
                </div>
              </div>
              {validation.buyer_name && inputs.reorder == "No" && (
                <div className="form-text text-danger">
                  {validation.buyer_name}
                </div>
              )}
            </div>

            <div className="mb-3 form-input-box">
              <label className="form-label">Company Name</label>
              <span className="form-star">
                <i className="fa-solid fa-star"></i>
              </span>
              <div className="form-input-box">
                <input
                  type="text"
                  name="company_name"
                  value={inputs.company_name || ""}
                  onChange={handleChange}
                  placeholder="Company Name"
                  className="form-control"
                />
                <div className="form-icon">
                  <i className="fa-solid fa-building"></i>
                </div>
              </div>
              {validation.company_name && inputs.reorder == "No" && (
                <div className="form-text text-danger">
                  {validation.company_name}
                </div>
              )}
            </div>

            <div className="mb-3 form-input-box">
              <label className="form-label">Buyer Email</label>
              <span className="form-star">
                <i className="fa-solid fa-star"></i>
              </span>
              <div className="form-input-box">
                <input
                  type="text"
                  name="buyer_email"
                  value={inputs.buyer_email || ""}
                  onChange={handleChange}
                  placeholder="Buyer Email"
                  className="form-control"
                />
                <div className="form-icon">
                  <i className="fa-solid fa-envelope"></i>
                </div>
              </div>
              {validation.buyer_email && (
                <div className="form-text text-danger">
                  {validation.buyer_email}
                </div>
              )}
            </div>
            <div className="mb-3 form-input-box">
              <label className="form-label">Buyer Phone Number</label>
              <span className="form-star">
                <i className="fa-solid fa-star"></i>
              </span>
              <div className="form-input-box">
                <input
                  type="tel"
                  name="buyer_phone"
                  value={inputs.buyer_phone || ""}
                  onChange={handleChange}
                  placeholder="Buyer Phone Number"
                  className="form-control"
                />
                <div className="form-icon">
                  <i className="fa-solid fa-phone-flip"></i>
                </div>
              </div>
              {validation.buyer_phone && inputs.reorder == "No" && (
                <div className="form-text text-danger">
                  {validation.buyer_phone}
                </div>
              )}
            </div>

            <div className="d-flex mb-3 dilivrydateflex">
              <div className="w-50">
                <div className="form-input-box">
                  <label className="form-label">Delivery Date</label>
                  <div className="form-input-box">
                    <input
                      type="date"
                      name="delivery_date"
                      value={inputs.delivery_date || ""}
                      onChange={updateDate}
                      placeholder="YYYY-MM-DD"
                      className="form-control"
                    />
                    <div className="form-icon">
                      <i className="fa-solid fa-calendar-days"></i>
                    </div>
                  </div>
                  {validation.delivery_date && inputs.reorder == "No" && (
                    <div className="form-text text-danger">
                      {validation.delivery_date}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-50">
                <div className="asreadycheckbox d-flex">
                  <div className=" form-input-box asreadycheckboxitem">
                    <div className="form-check check-box align-items-center d-flex">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="delivery_type"
                        value="asready"
                        checked={inputs.delivery_type === "asready"}
                        onClick={updateasready}
                        id="flexRadioDefault4"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault4"
                      >
                        <small>As Ready</small>
                      </label>
                    </div>
                  </div>
                  <div className="form-input-box asreadycheckboxitem">
                    <div className="form-check check-box align-items-center d-flex">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="delivery_type"
                        value="asap"
                        checked={inputs.delivery_type === "asap"}
                        onClick={updateasap}
                        id="flexRadioDefault7"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault7"
                      >
                        <small>ASAP - Rush</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-3 ">
              <label className="form-label">Terms</label>
              <span className="form-star">
                <i className="fa-solid fa-star"></i>
              </span>
              <div className="form-input-box">
                <input
                  type="text"
                  name="terms"
                  value={inputs.terms || ""}
                  onChange={handleChange}
                  placeholder="Terms"
                  className="form-control"
                />
                <div className="form-icon">
                  <i className="fa-solid fa-file-pen"></i>
                </div>
              </div>
              {validation.terms && inputs.reorder == "No" && (
                <div className="form-text text-danger">{validation.terms}</div>
              )}
            </div>

            <div className="mb-3 form-input-box heading-text-field">
              <label className="form-label">USPS Shipping Address</label>
              <span className="form-star">
                <i className="fa-solid fa-star"></i>
              </span>
              <div className="form-input-box">
                <textarea
                  className="form-control height-100"
                  placeholder="USPS Shipping Address"
                  name="usps_shipping_address"
                  value={inputs.usps_shipping_address || ""}
                  onChange={handleChange}
                ></textarea>

                <div className="form-icon">
                  <i className="fa-sharp fa-solid fa-location-dot"></i>
                </div>
              </div>
              {validation.usps_shipping_address && inputs.reorder == "No" && (
                <div className="form-text text-danger">
                  {validation.usps_shipping_address}
                </div>
              )}
            </div>
            <div className="mb-3 form-input-box heading-text-field">
              <label className="form-label">Fedex Shipping Address</label>
              <span className="form-star">
                <i className="fa-solid fa-star"></i>
              </span>
              <div className="check-box d-flex">
                <input
                  type="checkbox"
                  name="addresssame"
                  ref={checkbox}
                  id="sameAddress"
                  onChange={handleClick}
                />
                <label htmlFor="sameAddress">Click If Same</label>
              </div>
              <div className="form-input-box">
                <textarea
                  name="fedex_shipping_address"
                  className="form-control height-100"
                  placeholder="Fedex Shipping Address"
                  value={inputs.fedex_shipping_address || ""}
                  onChange={handleChange}
                  readOnly={checkbox.current?.checked}
                ></textarea>
                <div className="form-icon">
                  <i className="fa-sharp fa-solid fa-location-dot"></i>
                </div>
              </div>
              {validation.fedex_shipping_address && inputs.reorder == "No" && (
                <div className="form-text text-danger">
                  {validation.fedex_shipping_address}
                </div>
              )}
            </div>
            <div className="text-center">
              {stepvalidation == true ? (
                <button
                  onClick={() => handlecheckvalidations()}
                  className="btn site-btn"
                >
                  Next{" "}
                  <span>
                    <i className="fa-solid fa-chevron-right" disabled></i>
                  </span>
                </button>
              ) : (
                <button
                  onClick={() => handlecheckvalidations()}
                  className="btn site-btn"
                >
                  Next{" "}
                  <span>
                    <i className="fa-solid fa-chevron-right"></i>
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepOne;
