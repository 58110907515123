import React, { useState, useEffect } from "react";
const Rangslideritem = (props) => {
  const [keyword, setkeyword] = useState("");

  const [state, setState] = useState(props.currentValue);

  const updatepercentage = () => {
    let totalpercentage = 0;
    {
      props.inputs.category.map(function (object, i) {
        totalpercentage = totalpercentage + object.percentage;
      });
    }
    return totalpercentage;
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    let totalpercentage = 0;
    {
      props.inputs.category.map(function (object, i) {
        totalpercentage = Number(totalpercentage) + Number(object.percentage);
      });
    }

    if (totalpercentage == 0) {
      setState(value);
      totalpercentage = Number(totalpercentage) + Number(value);
      let cat = props.inputs.category;
      cat[props.index].percentage = parseInt(value);
      props.setInputs((values) => ({ ...values, category: cat }));
      props.updatepercentage();
    } else if (totalpercentage > 0) {
      totalpercentage = Number(totalpercentage) - Number(state);
      totalpercentage = Number(totalpercentage) + Number(value);
      console.log("Before" + totalpercentage);
      if (totalpercentage <= 100) {
        setState(value);
        let cat = props.inputs.category;
        cat[props.index].percentage = parseInt(value);
        props.setInputs((values) => ({ ...values, category: cat }));
        props.updatepercentage();

        console.log(totalpercentage);
        props.updatepercentage();
      }

      // if(totalpercentage <= 100 )
      // {
      //   console.log('rwe');
      //   let  cat = props.inputs.category;
      //   cat[props.index].percentage= parseInt(value);
      //   props.setInputs(values => ({ ...values, category: cat }))
      //   props.updatepercentage();
      // }
    }
  };
  const handleUpdateKeyword = (event) => {
    const value = event.target.value;
    let cat = props.inputs.category;
    cat[props.index].keyword = value;
    props.setInputs((values) => ({ ...values, category: cat }));
  };

  useEffect(() => {
    setState(props.currentValue);
  }, [props.currentValue]);
  return (
    <>
      <div className="row g-3 range-box align-items-center mb-3">
        <div className="col-12 col-md-4 col-lg-3">
          <input
            type="text"
            className="form-control"
            placeholder="Enter Keyword"
            value={props.keyword}
            onChange={handleUpdateKeyword}
          />
        </div>
        <div className="col-12 col-md-8  col-lg-9 slider-range-box">
          <div class="range-slider">
            <div id="tooltip"></div>

            <span class="range-slider__value"> {state}%</span>
            <input
              class="range"
              id={props.id}
              type="range"
              min="0"
              max="100"
              step={5}
              value={state}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Rangslideritem;
