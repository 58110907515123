import { useState, useEffect, useRef } from "react";
import { Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

import { ToastContainer, toast, Zoom } from "react-toastify";
import {
  updateSession,
  loaderBar,
  apibaseURl,
  currencySymbol,
  errorToast,
  successToast,
  loggedInCustomerDetails,
  getvalidationErr,
} from "../../../functions/Function";

const StepSeven = ({
  inputs,
  setInputs,
  validation,
  setvalidation,
  handleChange,
  validateInputs,
  validtionError,
  step,
  stepvalidation,
  nextStep,
  backStep,
  validationCheckStepSeven,
  updateorderId,
  setupdateorderId,
}) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const targetRef = useRef();
  const [terms, setTerms] = useState([]);
  const [grandtotal, setGrandtotal] = useState(0.0);
  const [dimensions, setDimensions] = useState(300);
  const [totalcheckbox, settotalcheckbox] = useState("No");
  const inputElement = useRef({});

  const checkbox = useRef();
  const handleClick = () => {
    if (checkbox.current.checked) {
      setInputs((values) => ({
        ...values,
        fedex_shipping_address: inputs.usps_shipping_address,
      }));
      validateInputs("fedex_shipping_address", inputs.usps_shipping_address);
    } else {
      setInputs((values) => ({ ...values, fedex_shipping_address: "" }));
    }
  };

  const clear = () => {
    inputElement.current.clear();
    setInputs((values) => ({ ...values, signature: "" }));
  };

  const trim = () => {
    if (inputElement.current.getSignaturePad().isEmpty() == false) {
      setInputs((values) => ({
        ...values,
        signature: inputElement.current
          .getTrimmedCanvas()
          .toDataURL("image/png"),
      }));
    }
  };

  const checkvalidation = () => {
    if (inputs.total == "" || inputs.total == 0) {
      errorToast("Please add total amount.", "top-right", "8000");
      return;
    }
    if (inputs.signature == "") {
      errorToast("Please add signature.", "top-right", "8000");
      return;
    }
    if (updateorderId > 0) {
      handleOrderUpdate();
    } else {
      handleOrderSubmit();
    }
  };
  const handleOrderSubmit = async () => {
    setDisabled(true);
    try {
      let res = await fetch(apibaseURl + "addOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs),
      });
      let output = await res.json();
      console.log(res);
      console.log(output);
      if (res.status === 200) {
        setDisabled(false);
        if (output.result == true) {
          validationCheckStepSeven();
          //validationCheckStepSeven();
        } else {
          if (output.validation == true) {
            errorToast(getvalidationErr(output.response), "top-right", "6000");
          } else {
            errorToast(output.msg, "top-right", "6000");
          }
        }
      }
    } catch (err) {
      setDisabled(false);
    }
  };

  const handleOrderUpdate = async () => {
    setDisabled(true);
    try {
      let res = await fetch(apibaseURl + "updateOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...inputs, order_id: updateorderId }),
      });
      let output = await res.json();
      if (res.status === 200) {
        setDisabled(false);
        if (output.result == true) {
          successToast(output.msg, "top-right", "6000");

          const timeoutId = setTimeout(() => {
            setupdateorderId(0);
            navigate("/orders");
          }, 2000);
        } else {
          if (output.validation == true) {
            errorToast(getvalidationErr(output.response), "top-right", "6000");
          } else {
            errorToast(output.msg, "top-right", "6000");
          }
        }
      }
    } catch (err) {
      setDisabled(false);
    }
  };

  const custombackstep = () => {
    backStep(6);
  };

  const totalPriceOfdisplayCustomization = () => {
    const sum = inputs.display.reduce(
      (acc, item) => acc + parseFloat(item.price),
      0
    );
    const formattedSum = sum.toFixed(2);
    return formattedSum;
  };

  const updateprepriced = (e) => {
    if (inputs.terms_and_conditions != "accept") {
      setInputs((values) => ({
        ...values,
        terms_and_conditions: e.target.value,
      }));
    } else {
      setInputs((values) => ({
        ...values,
        terms_and_conditions: " ",
      }));
    }
  };

  const getTerms = async () => {
    let res = await fetch(apibaseURl + "getTerms");
    let output = await res.json();
    if (res.status === 200) {
      if (output.result == true) {
        setTerms(output.response);
        //console.log(output.response);
      }
    }
  };

  useEffect(() => {
    let totalprice;
    const display_type_metal_qty = inputs.display_type_metal_qty;
    const display_type_metal_price = inputs.display_type_metal_price;
    const metalTotal = (
      display_type_metal_qty * display_type_metal_price
    ).toFixed(2);
    const display_type_wood_qty = inputs.display_type_wood_qty;
    const display_type_wood_price = inputs.display_type_wood_price;
    const woodTotal = (display_type_wood_qty * display_type_wood_price).toFixed(
      2
    );
    //const total = (parseFloat(metalTotal) + parseFloat(woodTotal)).toFixed(2);

    let total = 0;
    inputs.display_types.forEach((item) => {
      const quantity = parseFloat(item.qty);
      const price = parseFloat(item.display_type_metal_price);
      total += quantity * price;
    });

    let totalOfdisplayCustomization = totalPriceOfdisplayCustomization();
    let getDisplaytype = inputs.displaytype;
    if (getDisplaytype === "design_only") {
      totalprice = parseFloat(totalOfdisplayCustomization);
    } else if (getDisplaytype === "display_plus_design") {
      // totalprice = parseFloat(totalOfdisplayCustomization) + parseFloat(total);
      totalprice = parseFloat(total);
    } else {
      totalprice = parseFloat(total);
    }

    // console.log("Total" + totalOfdisplayCustomization + total.toFixed(2));
    setInputs((values) => ({ ...values, total: totalprice }));
    calculatePercentage(totalprice);
  }, [inputs.total]);

  const calculatePercentage = () => {
    const toalamount = inputs.total;
    const calculatePercentageAmount =
      (toalamount * inputs.discount_percentage) / 100;
    calculatePercentageAmount.toFixed(2);
    setInputs((values) => ({
      ...values,
      discount: calculatePercentageAmount,
    }));
    const SumOfallamout =
      Number(toalamount) - Number(calculatePercentageAmount);
    const grandtotal = parseFloat(SumOfallamout).toFixed(2);
    setGrandtotal(grandtotal);

    // console.log(calculatePercentageAmount.toFixed(2));
  };

  useEffect(() => {
    getTerms();
    calculatePercentage();
  }, [inputs.discount_percentage]);

  useEffect(() => {
    getTerms();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (targetRef.current) {
      setDimensions(targetRef.current.offsetWidth);
    }
  }, []);

  const updatediscountpercentage = (percentage) => {
    if (percentage >= 0) {
      setInputs((values) => ({
        ...values,
        discount_percentage: percentage,
      }));
    }
  };

  const checkedTotalCheckbox = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (value == "Yes") {
      settotalcheckbox("No");
    } else {
      settotalcheckbox("Yes");
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="section order-detail-2">
        <div className="container">
          <div className="form-box row">
            <div className="col-lg-12  inner-display-box-checks progress-bar-box">
              <div className="row">
                <div className="col-lg-12 col-sm-12 ">
                  <div className="text-center">
                    <h5>TERMS AND CONDITIONS</h5>
                  </div>
                  <div className="input-digits-box mt-4 overflow">
                    <div className="text-box">
                      {terms.length > 0 &&
                        terms.map((term, i) => (
                          <p>
                            {i + 1}. {term}
                          </p>
                        ))}
                    </div>

                    <div className="form-check check-box termsbox">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="terms_and_conditions"
                        value="accept"
                        checked={inputs.terms_and_conditions === "accept"}
                        onClick={updateprepriced}
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault7"
                      >
                        I have read and agree to the website terms and
                        conditions
                      </label>
                    </div>

                    <div className="input-digits-box mt-4 overflow">
                      <div className="form-text-area-6">
                        <div className="mb-3 form-input-box ">
                          <label className="form-label">
                            <h5>Additional Note</h5>
                          </label>
                          <div className="form-input-box">
                            <textarea
                              className="form-control"
                              rows="6"
                              name="additional_note"
                              value={inputs.additional_note || ""}
                              onChange={handleChange}
                              placeholder="Additional Note"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="price-input">
                      <div className="row g-3 needs-validation">
                        <div className="col-lg-12">
                          <div className="price-label-box">
                            <label className="form-label">Total</label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                $
                              </span>

                              <input
                                type="number"
                                name="total"
                                value={
                                  parseFloat(inputs.total).toFixed(2) || ""
                                }
                                className="form-control"
                                readOnly={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="price-label-box">
                            <label className="form-label">Discount</label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                In (%)
                              </span>

                              <input
                                type="number"
                                name="discount_percentage"
                                value={Number(inputs.discount_percentage) || ""}
                                onChange={(e) =>
                                  updatediscountpercentage(e.target.value)
                                }
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 d-flex justify-content-center align-items-center">
                          <div className="price-label-box">
                            <label className="form-label">Grand Total</label>
                            <div className="input-group has-validation">
                              <span
                                className="input-group-text"
                                id="inputGroupPrepend"
                              >
                                $
                              </span>

                              <input
                                type="number"
                                name="ua"
                                value={grandtotal}
                                className="form-control"
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <input
                            className="form-check-input"
                            type="radio"
                            name=""
                            value={totalcheckbox}
                            checked={totalcheckbox === "Yes"}
                            onClick={checkedTotalCheckbox}
                          />
                        </div>
                        {totalcheckbox === "Yes" && (
                          <div className="col-lg-12">
                            <div className="price-label-box">
                              <label className="form-label">
                                Manually Enter a Total
                              </label>
                              <div className="input-group has-validation">
                                <span
                                  className="input-group-text"
                                  id="inputGroupPrepends"
                                >
                                  $
                                </span>

                                <input
                                  type="number"
                                  name="override_total"
                                  value={inputs.override_total || ""}
                                  onChange={handleChange}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="sign-here-box">
                          <h6>Sign Here</h6>
                          {inputs.signature == "" && (
                            <>
                              <div className="sign-heres" ref={targetRef}>
                                <SignatureCanvas
                                  setInputs={setInputs}
                                  penColor="black"
                                  canvasProps={{
                                    width: dimensions,
                                    height: 200,
                                    className: "sigCanvas sign-here",
                                  }}
                                  ref={inputElement}
                                />
                              </div>
                              <div className="text-center mt-4 signbutton">
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={trim}
                                >
                                  Save signature
                                </button>
                                &nbsp; &nbsp; &nbsp;
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={clear}
                                >
                                  Clear signature
                                </button>
                              </div>
                            </>
                          )}

                          {inputs.signature && (
                            <div className="sign-heres">
                              <div className="sign-here signature">
                                <img src={inputs.signature} />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    onClick={() => custombackstep()}
                    className="btn site-btn backbtn"
                  >
                    {" "}
                    <span>
                      <i className="fa-solid fa-chevron-left" disabled></i>
                    </span>{" "}
                    Back
                  </button>

                  <button
                    onClick={() => checkvalidation()}
                    className="btn site-btn"
                    disabled={disabled}
                  >
                    Submit Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StepSeven;
